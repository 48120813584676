import { get, post } from './api.service'

const AuthService = {
	login(params: any, options?: any) {
		return post('/login', params, options)
	},

	register(userType: string, params: any, options?: any) {
		return get(`/users/${userType}`, params, options)
	},

	getCurrentUser(params) {
		return get('/me', params)
	},

	requestPasswordReset(params) {
		return post('/request-password-reset', params, { authenticate: false })
	},

	resetPassword(params) {
		return post('/reset-password', params, { authenticate: false })
	},

	verifyEmail(params) {
		return post('/verify-email', params, { authenticate: false })
	},

	verifyRequest(params) {
		return post('/verify-request', params, { authenticate: false })
	},

	requestEmailVerification(params: { email: string }) {
		return post('/request-verify-email', params, { authenticate: false })
	},
}
export default AuthService
