import { intersects, mapIdsFromRelation } from './helpers'

export const hasRights: any = (auth) => {
	const authUser = auth.user
	const checks = {
		// users
		canEditUser: function (user) {
			let userOrganisationIds = mapIdsFromRelation(user, 'organisations')

			if (auth.isAdmin()) {
				return true
			}
			if (user.id == authUser.id) {
				return true
			}
			if (
				auth.hasRole(['organisation-admin']) &&
				userOrganisationIds.includes(authUser.organisation_id)
			) {
				if (['admin', 'organisation-admin'].includes(user.role)) {
					// not allow to edit higher user inside organisation
					return false
				}
				return true
			}
			return false
		},

		canDeleteUser: function (user) {
			if (user.id == authUser.id) {
				return false
			}
			return checks.canEditUser(user)
		},

		// challenges
		canEditChallenge: function (challenge) {
			if (auth.isAdmin()) {
				return true
			}
			if (
				auth.hasRole(['organisation-admin']) &&
				authUser.organisation_ids.includes(challenge.organisationId)
			) {
				return true
			}
			if (
				auth.hasRole(['space-admin']) &&
				authUser.challenge_space_ids.includes(challenge.challengeSpaceId)
			) {
				return true
			}
			let challengeUsersIds = mapIdsFromRelation(challenge, 'challengeUsers')
			if (
				auth.hasRole(['company-owner']) &&
				challengeUsersIds.includes(authUser.id)
			) {
				return true
			}
			return false
		},

		canDeleteChallenge: function (challenge) {
			if (auth.isAdmin()) {
				return true
			}
			if (
				auth.hasRole(['organisation-admin', 'space-admin']) &&
				authUser.organisation_ids.includes(challenge.organisationId)
			) {
				return true
			}
			if (
				auth.hasRole(['space-admin']) &&
				authUser.challenge_space_ids.includes(challenge.challengeSpaceId)
			) {
				return true
			}
			let challengeUsersIds = mapIdsFromRelation(challenge, 'challengeUsers')
			if (
				auth.hasRole(['company-owner']) &&
				challengeUsersIds.includes(authUser.id)
			) {
				// yes is own challenge, but only allow deletion of open-challenges
				if (challenge.attributes.openChallengeType != 'private') {
					return true
				}
			}

			return false
		},

		// space
		canManageSpace(space) {
			// Allow if admin
			// Allow if space admin on the space of this challenge
			// Allow if org admin belongs to same org of spaceId
			if (auth.isAdmin()) {
				return true
			}

			if (
				auth.hasRole(['organisation-admin']) &&
				authUser.organisation_ids.includes(
					space?.relationships?.organisation?.data?.id
				)
			) {
				return true
			}
			if (
				auth.hasRole(['space-admin']) &&
				authUser.challenge_space_ids.includes(space.id)
			) {
				return true
			}
			return false
		},

		// teams
		canEditTeam(team) {
			if (auth.isAdmin()) {
				return true
			}
			if (
				auth.hasRole(['organisation-admin']) &&
				authUser.organisation_ids.includes(team.organisationId)
			) {
				return true
			}

			if (auth.hasRole(['space-admin'])) {
				const teamChallengeSpaceIds = mapIdsFromRelation(
					team,
					'challengeSpaces'
				)

				if (intersects(authUser.challenge_space_ids, teamChallengeSpaceIds)) {
					return true
				}
			}

			if (auth.hasRole(['student'])) {
				return (
					authUser.relationships.teams.data.filter((t) => t.id === team.id)
						.length > 0
				)
			}

			return false
		},

		canEditLockedTeam(team) {
			if (auth.hasRole(['student'])) {
				return false
			}

			return checks.canEditTeam(team)
		},
	}
	return { ...auth, ...checks }
}
