import { AnyAction } from 'redux'
import { merge, omit } from 'lodash'
import { HYDRATE } from 'next-redux-wrapper'
import {
	AUTHENTICATE,
	DEAUTHENTICATE,
	USER,
	USER_PROFILES,
	SET_USER_PROFILE,
	ERROR,
	UPDATE_AUTH_USER,
	UPDATE_ACCOUNT_CHALLENGE,
	UPDATE_ACCOUNT_CHALLENGE_USERS,
	UPDATE_OWN_COMPANY,
	CREATE_OWN_COMPANY,
	REMOVE_AUTH_CHALLENGE,
} from '../actions'

export interface AuthState {
	token: string | null
	user: { auth?: any; attributes?: {} } | null
	userProfiles: any[]
	selectedProfile: any
	challengeSpace: {}
	company: {}
	team: {}
	challenge: {}
	organisation: {}
	isLoggedin: boolean
	error: boolean
}

const initialState: AuthState = {
	token: null,
	user: null,
	userProfiles: [],
	selectedProfile: null,
	challengeSpace: {},
	company: {},
	team: {},
	challenge: {},
	organisation: {},
	isLoggedin: false,
	error: false,
}

export const authReducer = (
	state: AuthState = initialState,
	action: AnyAction
) => {
	switch (action.type) {
		case HYDRATE:
			// Attention! This will overwrite client state! Real apps should use proper reconciliation.
			return { ...state, ...action.payload.auth }
		case AUTHENTICATE:
			return { ...state, token: action.response, isLoggedin: true }
		case USER:
			return {
				...state,
				user: action.response.user
					? action.response.user[Object.keys(action.response.user)[0]]
					: null,
				challengeSpace: action.response.challengeSpace
					? action.response.challengeSpace
					: {},
				challenge: action.response.challenge ? action.response.challenge : {},
				organisation: action.response.organisation
					? action.response.organisation
					: {},
				team: action.response.team ? action.response.team : {},
				company: {
					...merge(
						action.response.address
							? action.response.address[Object.keys(action.response.address)[0]]
							: {},
						action.response.company
							? action.response.company[Object.keys(action.response.company)[0]]
							: {}
					),
				},
				isLoggedin: true,
			}
		case USER_PROFILES:
			return {
				...state,
				userProfiles: action.response.userProfile
					? Object.keys(action.response.userProfile).map(
							(p) => action.response.userProfile[p]
					  )
					: [],
			}
		case SET_USER_PROFILE:
			const { userProfile, teamFormationQuestionAnswer, challengeSpace } =
				action.response
			return {
				...state,
				selectedProfile: userProfile
					? {
							id: userProfile[Object.keys(userProfile)[0]].id,
							challengeSpaceId:
								challengeSpace[Object.keys(challengeSpace)[0]].id,
							answers: teamFormationQuestionAnswer
								? Object.keys(teamFormationQuestionAnswer)
										.map((k) => teamFormationQuestionAnswer[k])
										.map((a) => {
											return {
												...a.attributes,
												questionId: a.relationships?.question?.data?.id,
											}
										})
								: [],
					  }
					: null,
			}
		case UPDATE_ACCOUNT_CHALLENGE:
			if (state.challenge[action.challengeId]) {
				return {
					...state,
					challenge: {
						...state.challenge,
						[action.challengeId]: action.response.challenge[action.challengeId],
					},
				}
			} else {
				return state
			}
		case UPDATE_ACCOUNT_CHALLENGE_USERS:
			if (state.challenge[action.challengeId]) {
				return {
					...state,
					challenge: {
						...state.challenge,
						[action.challengeId]: {
							...state.challenge[action.challengeId],
							relationships: {
								...state.challenge[action.challengeId].relationships,
								challengeUsers:
									action.response.challenge[action.challengeId].relationships
										.challengeUsers,
							},
						},
					},
				}
			} else {
				return state
			}
		case UPDATE_AUTH_USER:
			return {
				...state,
				user: {
					...state.user,
					attributes: {
						...state.user.attributes,
						...action.response.user[Object.keys(action.response.user)[0]]
							.attributes,
					},
				},
			}
		case UPDATE_OWN_COMPANY:
			return {
				...state,
				company: {
					...state.company,
					...merge(
						action.response.address
							? action.response.address[Object.keys(action.response.address)[0]]
							: {},
						action.response.company
							? action.response.company[Object.keys(action.response.company)[0]]
							: {}
					),
				},
			}
		case CREATE_OWN_COMPANY:
			return {
				...state,
				company: {
					...state.company,
					...merge(
						action.response.address
							? action.response.address[Object.keys(action.response.address)[0]]
							: {},
						action.response.company
							? action.response.company[Object.keys(action.response.company)[0]]
							: {}
					),
				},
			}
		case REMOVE_AUTH_CHALLENGE:
			return {
				...state,
				challenge: omit(state.challenge, action.response.challengeId),
			}
		case DEAUTHENTICATE:
			return initialState
		case ERROR:
			return { ...state, error: action.response }
		default:
			return state
	}
}
