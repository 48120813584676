import { post, get, put, remove } from './api.service'

const UserService = {
	createCompanyOwner(params: any) {
		return post('/users/company-owner', params)
	},

	createSpaceAdmin(params: any) {
		return post('/users/space-admin', params)
	},

	createStudent(params: any) {
		return post('/users/student', params)
	},

	getUsers(params: any) {
		return get('/users', params)
	},

	getUser(userId: string) {
		return get(`/users/${userId}`)
	},

	updateUserState(userId: string, params: any) {
		return put(`/users/${userId}/state`, params)
	},

	sendEmailVerificationRequest(userId: string) {
		return get(`/users/${userId}/send-email-verification-request`)
	},

	createUser(params: any) {
		return post('/users', params)
	},

	deleteUser(userId: string) {
		return remove(`/users/${userId}`)
	},

	updateUser(userId: string, params: any) {
		return put(`/users/${userId}`, params)
	},

	getUserProfiles(userId: string) {
		return get(`/users/${userId}/profiles`)
	},

	getUserProfile(userId: string, profileId: string) {
		return get(`/users/${userId}/profiles/${profileId}`)
	},

	updateUserProfile(userId: string, profileId: string, params: any) {
		return put(`/users/${userId}/profiles/${profileId}`, params)
	},

	createUserProfile(userId: string, params: any) {
		return post(`/users/${userId}/profiles`, params)
	},

	deleteUserProfile(userId: string, profileId: string) {
		return remove(`/users/${userId}/profiles/${profileId}`)
	},
}

export default UserService
