import { AUTHENTICATE, DEAUTHENTICATE, USER, USER_PROFILES } from './'
import { login } from '../../utils/auth'
import AuthService from '../../services/auth.service'
import UserService from '../../services/user.service'

const authenticate = (payload: { email: string; password: string }) => {
	const env = process.env.ENVIRONMENT

	return async (dispatch: any) => {
		return await AuthService.login(payload, { authenticate: false }).then(
			async (response: any) => {
				if (!response || typeof response === 'undefined') {
					throw new Error('Invalid credentials')
				} else if (response.error) {
					throw new Error(response.error)
				}

				login(response.access_token)
				dispatch({
					type: AUTHENTICATE,
					response: response.access_token,
				})

				let user = null

				// Set Rollbar userId after logging in
				if (
					response.access_token &&
					(env === 'production' || env === 'staging')
				) {
					const res = await AuthService.getCurrentUser({})
					user = res.user
						? Object.keys(res.user).map((key) => res.user[key])[0]
						: null

					if (user && window.Rollbar) {
						window.Rollbar.configure({
							payload: {
								person: {
									id: user.id, // required
								},
							},
						})
					}
				}
				// Do some router event here, e.g. redirect to dashboard?
			}
		)
	}
}

// gets the token from the cookie and saves it in the store
const reauthenticate = (token) => {
	return (dispatch) => {
		dispatch({
			type: AUTHENTICATE,
			response: token,
		})
	}
}

// removing the token
const deauthenticate = () => {
	return (dispatch) => {
		dispatch({ type: DEAUTHENTICATE })
	}
}

const getUserProfiles = (userId) => {
	return async (dispatch) => {
		await UserService.getUserProfiles(userId).then((response) => {
			if (response) {
				dispatch({
					type: USER_PROFILES,
					response: response,
				})
			}
		})
	}
}

const getUser = (payload?) => {
	return async (dispatch) => {
		await AuthService.getCurrentUser(payload).then((response) => {
			if (response) {
				dispatch({
					type: USER,
					response: response,
				})
			}
		})
	}
}

const requestPasswordReset = (payload: { email: string }) => {
	return async () => {
		await AuthService.requestPasswordReset(payload).then((response: any) => {
			if (response.errors) {
				throw new Error(response.errors.title)
			}
		})
	}
}

const resetPassword = (payload: {
	token: string
	email: string
	password: string
	password_confirmed: string
}) => {
	return async () => {
		await AuthService.resetPassword(payload)
			.then(() => {})
			.catch((error) => console.log(error))
	}
}

const verifyEmail = (payload: { id: string; signature: string }) => {
	return async () => {
		await AuthService.verifyEmail(payload).then((response: any) => {
			if (response.errors) {
				throw new Error(response.errors.title)
			}
		})
	}
}

const verifyRequest = (payload: {
	id: string
	token: string
	signature: string
}) => {
	return async () => {
		await AuthService.verifyRequest(payload).then((response: any) => {
			if (response.errors) {
				throw new Error(response.errors.title)
			}
		})
	}
}

export {
	authenticate,
	reauthenticate,
	deauthenticate,
	getUser,
	requestPasswordReset,
	resetPassword,
	verifyEmail,
	verifyRequest,
	getUserProfiles,
}
