import { combineReducers } from 'redux'
import { publicReducer } from './public-reducer'
import { authReducer } from './auth-reducer'
import { accountReducer } from './account-reducer'

const reducers = {
  public: publicReducer,
  auth: authReducer,
  account: accountReducer
}

export default combineReducers(reducers)
