import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import NProgress from 'nprogress'
import Router from 'next/router'
import { wrapper } from '../redux/store'
import TagManager from 'react-gtm-module'

import '../assets/styles/index.css'
import '../assets/styles/styles.scss'

const basePath = process.env.BASE_PATH

interface Props {
	Component: React.Component
	pageProps: any
}

Router.events.on('routeChangeStart', () => {
	NProgress.start()
})

Router.events.on('routeChangeComplete', () => {
	const tagManagerArgs = {
		dataLayer: {
			page: Router.pathname,
			event: 'pageView',
		},
	}
	TagManager.dataLayer(tagManagerArgs)
	NProgress.done()
})

Router.events.on('routeChangeError', () => NProgress.done())

declare global {
	interface Window {
		Rollbar: any
	}
}

class MyApp extends App<Props> {
	static async getInitialProps({ Component, ctx }) {
		const pageProps = Component.getInitialProps
			? await Component.getInitialProps(ctx)
			: {}

		return { pageProps }
	}

	componentDidMount() {
		const tagManagerArgs = {
			gtmId: process.env.GTM_KEY,
			dataLayer: {
				page: Router.pathname,
				event: 'pageView',
			},
		}
		TagManager.initialize(tagManagerArgs)
	}

	componentDidCatch(error) {
		window.Rollbar?.error(error)
	}

	render() {
		const { Component, pageProps } = this.props
		const defaultTitle = 'Company challenges solved by students'
		const defaultDescription =
			'Real-life challenge platform allowing students to gain relevant experience, educators to improve their course and companies to get their challenges solved.'

		return (
			<div className="app_wrapper">
				<Head>
					<title>{`${
						pageProps.title ? pageProps.title : defaultTitle
					} | Master Challenge`}</title>
					<meta
						name="viewport"
						content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,minimal-ui"
					/>
					<meta
						name="title"
						key="meta-title"
						content={`${
							pageProps.title ? pageProps.title : defaultTitle
						} | Master Challenge`}
					/>
					<meta
						name="description"
						key="meta-description"
						content={
							pageProps.description ? pageProps.description : defaultDescription
						}
					/>

					<meta
						property="og:image"
						content={`${basePath}/static/img/${
							pageProps.image ? pageProps.image : 'master_challenge.png'
						}`}
					/>
					<meta
						property="twitter:image"
						content={`${basePath}/static/img/${
							pageProps.image ? pageProps.image : 'master_challenge.png'
						}`}
					/>

					<meta
						property="og:url"
						content={pageProps.url ? pageProps.url : basePath}
					/>
					<meta
						property="og:title"
						content={`${
							pageProps.title
								? pageProps.title
								: 'Company challenges solved by students'
						} | Master Challenge`}
					/>
					<meta
						property="og:description"
						content={
							pageProps.description ? pageProps.description : defaultDescription
						}
					/>

					<meta
						property="twitter:url"
						content={pageProps.url ? pageProps.url : basePath}
					/>
					<meta
						property="twitter:title"
						content={`${
							pageProps.title ? pageProps.title : defaultTitle
						} | Master Challenge`}
					/>
					<meta
						property="twitter:description"
						content={
							pageProps.description ? pageProps.description : defaultDescription
						}
					/>
				</Head>
				<Component {...pageProps} />
			</div>
		)
	}
}

export default wrapper.withRedux(MyApp)
