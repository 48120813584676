// !IMPORTANT keep order of roles in order or rank
// this order is used to check the user auth level
export const ROLES = [
	{ value: 'admin', label: 'Admin' },
	{ value: 'organisation-admin', label: 'Organisation admin' },
	{ value: 'space-admin', label: 'Space admin' },
	{ value: 'company-owner', label: 'Company owner' },
	{ value: 'student', label: 'Student' },
]

export const USER_STATES = [
	{ value: 0, label: 'Inactive' },
	{ value: 1, label: 'Active' },
]

export const ORGANISATION_STATES = [
	{ value: 0, label: 'Inactive' },
	{ value: 1, label: 'Active' },
]

export const COMPANY_STATES = [
	{ value: 0, label: 'Inactive' },
	{ value: 1, label: 'Active' },
]

export const CHALLENGE_STATES = [
	{ value: 0, label: 'Unapproved', slug: 'unapproved' },
	{ value: 1, label: 'Approved', slug: 'approved' },
	{ value: 2, label: 'Pending approval', slug: 'pending-approval' },
]

export const CHALLENGE_SPACE_STATES = [
	{ value: 0, label: 'Inactive' },
	{ value: 1, label: 'Active' },
]

export const CHALLENGE_SPACE_VISIBILITY_STATES = [
	{ value: true, label: 'Public' },
	{ value: false, label: 'Private' },
]

export const CHALLENGE_SPACE_EXPIRATION_STATES = [
	{ value: 0, label: 'Active' },
	{ value: 1, label: 'Expired' },
]

export const CHALLENGE_SPACE_APPLICATION_TYPES = [
	{ value: 'individual', label: 'Individual' },
	{ value: 'team', label: 'Team' },
]

export const CHALLENGE_SPACE_PHASES = [
	{
		value: 'challenge_submission',
		label: 'Challenge submission & Team registration',
	},
	{
		value: 'challenge_submission_tf',
		label: 'Challenge submission & Team formation',
	},
	{ value: 'team_formation', label: 'Team formation' },
	{ value: 'challenge_ranking', label: 'Challenge ranking' },
	{ value: 'student_ranking', label: 'Team ranking' },
	{ value: 'matching', label: 'Matching' },
	{ value: 'running', label: 'Running' },
]

export const CHALLENGE_SPACE_SORT_OPTIONS = [
	{ value: '-created_at', label: 'Newest' },
	{ value: 'challenge_start_date', label: 'Start date' },
	{
		value: 'challenge_submit_deadline_expired,challenge_submit_deadline',
		label: 'Submit deadline',
	},
]

export const MAILING_RECIPIENTS = [
	{ value: 'team', label: 'All teams' },
	{ value: 'challenge', label: 'All challenge owners' },
]

export const MAILING_CHALLENGE_FILTERS = [
	{
		value: 'challenge_approved',
		label: 'have approved challenge(s)',
	},
	{
		value: 'challenge_pending_approved',
		label: 'have challenge(s) that are pending for approval',
	},
	{
		value: 'challenge_disapproved',
		label: 'have disapproved challenge(s)',
	},
	{
		value: 'challenge_with_ranking',
		label: 'have submitted their team ranking',
	},
	{
		value: 'challenge_without_ranking',
		label: 'have not submitted their team ranking',
	},
	{
		value: 'challenge_not_selected_by_team',
		label: 'have not been selected by any team',
	},
	{
		value: 'challenge_has_match',
		label: 'have been matched to a team',
	},
	{
		value: 'challenge_has_no_match',
		label: 'have not been matched to a team',
	},
]

export const MAILING_TEAM_FILTERS = [
	{
		value: 'team_with_ranking',
		label: 'have submitted their challenge ranking',
	},
	{
		value: 'team_without_ranking',
		label: 'have not submitted their challenge ranking',
	},
	{
		value: 'team_has_match',
		label: 'have been matched to a challenge',
	},
]

export const TEAM_FORMATION_CONFIG_QUESTION_TYPES = [
	{
		value: 'select',
		label: 'Select',
		description: 'Single value dropdown',
	},
	{
		value: 'scale',
		label: 'Scale',
		description: 'E.g. fully disagree - fully agree',
	},
	{
		value: 'ranking',
		label: 'Ranking',
		description: 'E.g. a top 5 of colours',
	},
	{
		value: 'text',
		label: 'Open question',
		description: 'Get input from students (max words)',
	},
]

export const TEAM_FORMATION_CONFIG_RANKING_OPTIONS = [
	{
		value: 2,
		label: 'Top 2',
	},
	{
		value: 3,
		label: 'Top 3',
	},
	{
		value: 4,
		label: 'Top 4',
	},
	{
		value: 5,
		label: 'Top 5',
	},
	{
		value: 6,
		label: 'Top 6',
	},
	{
		value: 7,
		label: 'Top 7',
	},
	{
		value: 8,
		label: 'Top 8',
	},
	{
		value: 9,
		label: 'Top 9',
	},
	{
		value: 10,
		label: 'Top 10',
	},
	{
		value: 11,
		label: 'Top 11',
	},
	{
		value: 12,
		label: 'Top 12',
	},
	{
		value: 13,
		label: 'Top 13',
	},
	{
		value: 14,
		label: 'Top 14',
	},
	{
		value: 15,
		label: 'Top 15',
	},
	{
		value: 16,
		label: 'Top 16',
	},
	{
		value: 17,
		label: 'Top 17',
	},
	{
		value: 18,
		label: 'Top 18',
	},
	{
		value: 19,
		label: 'Top 19',
	},
	{
		value: 20,
		label: 'Top 20',
	},
]

export const GENERAL_QUESTION_TYPES = [
	{
		value: 'select',
		label: 'Select',
		description: 'Single value dropdown',
	},
	{
		value: 'select.multi',
		label: 'Multiselect',
		description: 'Multiple choice dropdown',
	},
	{
		value: 'scale',
		label: 'Scale',
		description: 'E.g. fully disagree - fully agree',
	},
	{
		value: 'ranking',
		label: 'Ranking',
		description: 'E.g. a top 5 of colours',
	},
	{
		value: 'text',
		label: 'Open question',
		description: 'Get input from companies (max words)',
	},
]

export const OPEN_CHALLENGE_TYPES_SELECTABLE = [
	{
		value: 'global',
		label: 'Global',
	},
	{
		value: 'organisation',
		label: 'Organisation',
	},
]

export const USER_TYPE_SELECT = [
	{ value: 'internal', label: 'Internal' },
	{ value: 'external', label: 'External' },
]

export const UNAPPROVE_CHALLENGE_REASONS = [
	{
		value: 'not_sufficiently_match',
		label: 'Your challenge does not sufficiently match the aim of the course.',
		text: 'The reason for this is that your challenge does not sufficiently match the aim of the course.',
	},
	{
		value: 'received_too_many_challenges',
		label: 'We received too many challenges.',
		text: 'The reason for this is that we received too many challenges.',
	},
	{
		value: 'better_fit_with_other_challenges',
		label:
			'We received too many challenges, and after reviewing all challenges, we saw a better fit with other challenges.',
		text: 'The reason for this is that we received too many challenges, and after reviewing all challenges, we saw a better fit with other challenges.',
	},
	{
		value: 'other',
		label: 'Other',
		text: 'Other',
	},
]
