import Swal from 'sweetalert2'

const Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

const formatTitle = (title: string | any) => {
	if (typeof title == 'object') {
		let strTitle = title.title
		if (title?.sub_title) {
			strTitle += '<br><small>' + title.sub_title + '</small>'
		}
		return strTitle
	} else {
		return title
	}
}

export default {
	showSuccess(title: string | object, options?: object) {
		Toast.fire({
			icon: 'success',
			title: formatTitle(title),
			...(options ? options : {}),
		})
	},

	showError(title: string | object, options?: object) {
		Toast.fire({
			icon: 'error',
			title: formatTitle(title),
			...(options ? options : {}),
		})
	},

	showWarning(title: string | object, options?: object) {
		Toast.fire({
			icon: 'warning',
			title: formatTitle(title),
			...(options ? options : {}),
		})
	},
}
