import { Component } from 'react'
import Router from 'next/router'
import redirect from './redirect'
import { ROLES } from './constants'
import { isAllowed, mapIdsFromRelation } from './helpers'
import { refreshToken } from '../services/api.service'
import { setCookie, getCookie, removeCookie } from './session'
import { reauthenticate, getUser } from '../redux/actions/authentication'
import { wrapper } from '../redux/store'
import { useSelector } from 'react-redux'
import { hasRights } from './rights'

function login(token: string) {
	setCookie('token', token)
}

const logout = (redirect?: boolean) => {
	if (process.browser) {
		removeCookie('token')
		// to support logging out from all windows
		window.localStorage.setItem('logout', JSON.stringify(Date.now()))

		if (
			redirect &&
			Router.asPath !== '/login' &&
			Router.pathname !== '/challenge-spaces/[challengeSpaceId]' &&
			Router.asPath.indexOf('?redirect=') != -1
		) {
			Router.push(`/login?redirect=${Router.asPath}`)
		} else if (
			Router.asPath !== '/login' &&
			Router.pathname !== '/challenge-spaces/[challengeSpaceId]'
		) {
			Router.push('/login')
		}
	}
}

const getToken = ({ req }: any) => {
	return getCookie('token', req)
}

const isAuthenticated = (ctx) => {
	const token = getToken(ctx)
	return token && token !== 'null' && token !== 'undefined' ? true : false
}

const redirectIfNotAuthenticated = (ctx) => {
	redirect(`/login`, ctx, true)
	return true
}

const redirectIfNotAllowed = (routeString: string, user: {}, ctx) => {
	if (routeString.includes('management') && !isAllowed(user, ['admin'])) {
		redirect('/account', ctx, false)
	}
}

// Gets the display name of a JSX component for dev tools
const getDisplayName = (Component) =>
	Component.displayName || Component.name || 'Component'

function withAuthSync(WrappedComponent, redirectOnUnauthorized = false) {
	class AuthSyncComponent extends Component<{ getUser(params: any): void }> {
		static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`

		public static getInitialProps = wrapper.getInitialPageProps(
			(store) => async (ctx: any) => {
				const token = getToken(ctx)

				if (token && token !== 'null' && token !== 'undefined') {
					// Reauthenticate with token from request
					await store.dispatch(reauthenticate(token) as any)

					const params = {
						include:
							'challengeSpaces,teams,teams.challengeSpaces,challenges,challenges.challengeSpace,challenges.challengeUsers,company,company.address,organisations,challengeSpaces.subject,challengeSpaces.organisation',
					}
					try {
						await store.dispatch(getUser(params) as any)
					} catch (e: any) {
						if (e.message === 'unauthorized') {
							// Refresh token if expired
							const tokenResponse: any = await refreshToken()
							const jsonTokenResponse = await tokenResponse.json()
							const token = jsonTokenResponse.access_token

							// Reauthenticate with new token and do new request
							await store.dispatch(reauthenticate(token) as any)
							await store.dispatch(getUser(params) as any)
						}
					}

					const state = await store.getState()
					const user = state.auth.user
					/**
					 * Redirect users if they're no admins on management routes
					 * @param  {undefined|true} process.browser
					 * @return {Function(redirect)}
					 */
					if (process.browser) {
						redirectIfNotAllowed(Router.asPath, user, ctx)
					} else {
						redirectIfNotAllowed(ctx.pathname, user, ctx)
					}
				} else {
					// Logout if no token exists and user needs to be redirected
					if (redirectOnUnauthorized) {
						// await store.dispatch(deauthenticate() as any)
						redirectIfNotAuthenticated(ctx)
					}
				}

				let componentProps

				try {
					componentProps = WrappedComponent.getInitialProps
						? await WrappedComponent.getInitialProps(ctx)
						: {} && WrappedComponent.getServerSideProps
						? await WrappedComponent.getServerSideProps(ctx)
						: {}
					const state = await store.getState()
					const auth = useAuth(state.auth.user)
					return {
						...componentProps,
						auth: {
							...componentProps.auth,
							token: token,
							user: { ...state.auth.user, auth: auth },
						},
					}
				} catch (e: any) {
					// Unauthorized and server side
					if (e.message === 'unauthorized') {
						removeCookie('token')

						if (redirectOnUnauthorized) {
							// await store.dispatch(deauthenticate() as any)
							redirectIfNotAuthenticated(ctx)
						}

						return {
							...componentProps,
							auth: { isLoggedin: false, token: null },
						}
					}
				}
			}
		)

		constructor(props) {
			super(props)

			this.syncLogout = this.syncLogout.bind(this)
		}

		componentDidMount() {
			window.addEventListener('storage', this.syncLogout)
		}

		componentWillUnmount() {
			window.removeEventListener('storage', this.syncLogout)
			window.localStorage.removeItem('logout')
		}

		syncLogout(event) {
			if (event.key === 'logout' && process.browser) {
				console.log('logged out from storage!')
				Router.push('/login')
			}
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	return AuthSyncComponent
}

const useAuth: any = (thisUser = false) => {
	let user
	if (thisUser) {
		user = thisUser // hack for classComponents
	} else {
		user = useSelector((state: any) => state.auth.user)
	}
	let role = user?.attributes?.role ?? 'guest'

	// easy refrences
	if (user) {
		user.organisation_id = user?.relationships?.organisations?.data[0]?.id
		user.organisation_ids = mapIdsFromRelation(user, 'organisations')
		user.challenge_space_ids = mapIdsFromRelation(user, 'challengeSpaces')
		user.belongsToMultipleOrganisation =
			user?.relationships?.organisations?.data.length > 1 ? true : false

		if (user?.relationships?.company != undefined) {
			user.company_id = user?.relationships?.company?.data?.id
		} else {
			user.company_id = user?.relationships?.companies?.data[0]?.id
		}
	}

	let auth = {
		user: user,
		role: role,
		// also on auth object
		organisation_id: user?.organisation_id ?? false,

		company_id: user?.company_id ?? false,
		// handy functions
		isAdmin: function () {
			return role == 'admin'
		},
		hasRole: function (roles: Array<string>) {
			return roles.includes(role)
		},
		minRole: function (min_rol: string) {
			let role_arr = ROLES.map((r) => r.value)
			return role_arr.indexOf(role) <= role_arr.indexOf(min_rol)
		},
	}

	auth.user.isAdmin = auth.isAdmin
	auth.user.hasRole = auth.hasRole
	auth.user.minRole = auth.minRole

	return hasRights(auth)
}

export {
	isAuthenticated,
	redirectIfNotAuthenticated,
	getToken,
	login,
	logout,
	withAuthSync,
	useAuth,
}
