import { createStore, applyMiddleware, AnyAction, Store } from 'redux'
import { createWrapper, Context } from 'next-redux-wrapper'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { setToken } from '../services/api.service'
import { getCookie } from '../utils/session'
import { PublicState } from './reducers/public-reducer'
import { AccountState } from './reducers/account-reducer'
import { AuthState } from './reducers/auth-reducer'

import rootReducer from './reducers'

export interface StoreState {
	public: PublicState
	auth: AuthState
	account: AccountState
}

// @ts-ignore
const saveAuthToken = (store: any) => (next: any) => (action: any) => {
	if (action.type === 'AUTHENTICATE') {
		// after a successful login, update the token in the API
		setToken(action.response)
	} else if (action.type === 'DEAUTHENTICATE') {
		// Remove token if logout action
		setToken(null)
	} else {
		if (process.browser) {
			let token = getCookie('token', null)
			if (token) {
				setToken(token)
			}
		}
	}

	// continue processing this action
	return next(action)
}

// create a makeStore function
const makeStore = () =>
	createStore(
		rootReducer,
		{},
		composeWithDevTools(
			applyMiddleware(
				thunk as ThunkMiddleware<Context, AnyAction>,
				saveAuthToken
			)
		)
	)

const wrapper = createWrapper<Store<StoreState>>(makeStore, {
	debug: typeof window !== 'undefined' && process.env.NODE_ENV !== 'production',
})

export { wrapper, makeStore }
