import Router from 'next/router'

const redirect = (
	target: string,
	ctx: { res?: any; asPath?: any } = {},
	redirect?: boolean
) => {
	if (ctx.res) {
		if (redirect && target.indexOf('?redirect=') === -1) {
			ctx.res.writeHead(303, { Location: target + `?redirect=${ctx.asPath}` })
		} else {
			ctx.res.writeHead(303, { Location: target })
		}
		ctx.res.end()
	} else {
		// In the browser, we just pretend like this never even happened ;)
		if (redirect && target.indexOf('?redirect=') === -1) {
			Router.replace(
				target + `?redirect=${ctx.asPath ? ctx.asPath : Router.asPath}`
			)
		} else {
			Router.replace(target)
		}
	}
}

export const redirectHard = (path: string) => {
	// them this dirty, we need to fix this
	window.location.href = path
}

export default redirect
