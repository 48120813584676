import merge from 'lodash/merge'
import { HYDRATE } from 'next-redux-wrapper'
import { AnyAction } from 'redux'
import {
	SET_FEATURED_CHALLENGE_SPACES,
	SET_CHALLENGE_SPACES,
	LOAD_MORE_CHALLENGE_SPACES,
	SET_SUBMIT_CHALLENGE_SPACE,
	SET_PUBLIC_ORGANISATIONS,
	SET_PUBLIC_SUBJECTS,
	SET_SELECTED_ORGANISATION,
	SET_PUBLIC_EDUCATION_TYPES,
} from '../actions'

export interface PublicState {
	meta: {}
	challengeSpace: {}
	organisation: {}
	educationType: {}
	subject: {}
	selectedOrganisation: null | {}
}

const initialState: PublicState = {
	meta: {},
	challengeSpace: {},
	organisation: {},
	educationType: {},
	subject: {},
	selectedOrganisation: null,
}

export const publicReducer = (
	state: PublicState = initialState,
	action: AnyAction
) => {
	switch (action.type) {
		case HYDRATE:
			// Attention! This will overwrite client state! Real apps should use proper reconciliation.
			return { ...state, ...action.payload.public }
		// return state
		case SET_FEATURED_CHALLENGE_SPACES:
		case SET_SUBMIT_CHALLENGE_SPACE:
		case SET_PUBLIC_ORGANISATIONS:
			return merge({}, state, merge({}, action.response))
		case SET_PUBLIC_EDUCATION_TYPES:
			return {
				...state,
				educationType: action.response.educationType,
			}
		case SET_PUBLIC_SUBJECTS:
			return {
				...state,
				subject: { ...merge(state.subject, action.response.subject) },
			}
		case SET_CHALLENGE_SPACES:
			return {
				...merge(state, action.response),
				subject: { ...merge(state.subject, action.response.subject) },
				challengeSpace: action.response.challengeSpace,
			}
		case LOAD_MORE_CHALLENGE_SPACES:
			return {
				...merge(state, action.response),
				subject: { ...merge(state.subject, action.response.subject) },
				challengeSpace: {
					...state.challengeSpace,
					...action.response.challengeSpace,
				},
			}
		case SET_SELECTED_ORGANISATION:
			return {
				...merge(state, action.response),
				selectedOrganisation:
					action.response && action.response.organisation
						? action.response.organisation[
								Object.keys(action.response.organisation)[0]
						  ]
						: null,
			}
		default:
			return state
	}
}
